import logo from './temp_image.jpg';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Marhaba!
        </p>
        <p>🌟 Welcome to Online Medina : The Moroccan Way of Life 🌟</p>
        <p>🛍️ Online store opening early September, to purchase unique Moroccan artisanal products</p>
        <p>🛍️ Boutique en ligne qui ouvrira début septembre, pour acheter des produits artisanaux marocains uniques</p>

        <p>
          Stay up to date:
        </p>
        <div className="social-links">
          <FacebookLink/>
          <InstagramLink/>
        </div>
      </header>
    </div>
  );
}


const FacebookLink = () => {
  return (
    <a href="https://www.facebook.com/share/PGjzsWZYA9tTTsjS/" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-facebook fa-2x" style={{ color: '#3b5998' }}></i>
    </a>
  );
};

const InstagramLink = () => {
  return (
    <a href="https://www.instagram.com/onlinemedina_morocco/" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-instagram fa-2x" style={{ color: '#E1306C' }}></i>
    </a>
  );
};

export default App;
